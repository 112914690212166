/*
 date.js imposes some limitations on 'date.format'. It only handles "yymmdd" and "ddmmyy" and mmddyy", so no "yyyymmdd", "mmddyyyy", or other variations.
 i18n.js imposes a limitation on currency codes. It allows for a 'unit', which you can put the symbol or code in, but no support for both. I18n.js also doesn't normally support "abbr_day_names_short", I modified the library to support it.
 calendar.js and i18n.js handle dates a little differently. This is why 'month_name' does NOT start with 0.
 */
I18n.translations = I18n.translations || {};

//Czech
I18n.translations['cs'] = $.extend(true, I18n.translations['cs'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%-d.%-m.%Y",
    //        "short": "%-d.%-m.%Y",
    //        "medium": "%-d.%-m.%Y",
    //        "long": "%-d. %B %Y",
    //        "full": "%A, %-d. %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": ".",
    //    "start_of_week": 1,
    //    "day_names": ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
    //    "abbr_day_names": ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
    //    "abbr_day_names_short": ["N", "P", "Ú", "S", "Č", "P", "S"],
    //    "month_names": [0, "leden", "únor", "březen", "duben", "květen", "červen", "červenec", "srpen", "září", "říjen", "listopad", "prosinec"],
    //    "abbr_month_names": [0, "led.", "ún.", "břez.", "dub.", "květ.", "červ.", "červen.", "srp.", "zár.", "ríj.", "list.", "pros."],
    //    "abbr_month_names_short": [0, "L", "Ú", "B", "D", "K", "C", "J", "A", "S", "O", "N", "D"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": " "
        },
        "currency": {
            "format": {
                "unit": "Kč",
                "code": "CZK",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//Danish
I18n.translations['da'] = $.extend(true, I18n.translations['da'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%It %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d-%m-%Y",
    //        "short": "%d-%m-%Y",
    //        "medium": "%d-%m-%Y",
    //        "long": "%-d. %B %Y",
    //        "full": "%-d. %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "-",
    //    "start_of_week": 1,
    //    "day_names": ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
    //    "abbr_day_names": ["sø", "ma", "ti", "on", "to", "fr", "lø"],
    //    "abbr_day_names_short": ["S", "M", "T", "o", "T", "F", "L"],
    //    "month_names": [0, "januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"],
    //    "abbr_month_names": [0, "jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sept", "okt", "nov", "dec"],
    //    "abbr_month_names_short": [0, "J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "kr",
                "code": "DKK",
                "precision": 2,
                "format": "%u %n"
            }
        }
    }
});

//German - Germany
I18n.translations['de'] = $.extend(true, I18n.translations['de'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d.%m.%Y",
    //        "short": "%d.%m.%Y",
    //        "medium": "%d.%m.%Y",
    //        "long": "%-d. %B %Y",
    //        "full": "%A, %-d. %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": ".",
    //    "start_of_week": 1,
    //    "day_names": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    //    "abbr_day_names": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    //    "abbr_day_names_short": ["S", "M", "D", "M", "D", "F", "S"],
    //    "month_names": [0, "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    //    "abbr_month_names": [0, "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dez"],
    //    "abbr_month_names_short": [0, "J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//German - Austria
I18n.translations['de-AT'] = $.extend(true, I18n.translations['de-AT'] || {}, I18n.translations['de']); // Pick up all the properties from default 'de'
I18n.translations['de-AT'] = $.extend(true, I18n.translations['de-AT'] || {}, { // Override what is necessary
    "number": {
        "currency": {
            "format": {
                "format": "%u %n"
            }
        }
    }
});

//German - Switzerland
I18n.translations['de-CH'] = $.extend(true, I18n.translations['de-CH'] || {}, I18n.translations['de']); // Pick up all the properties from default 'de'
I18n.translations['de-CH'] = $.extend(true, I18n.translations['de-CH'] || {}, { // Override what is necessary
    "number": {
        "format": {
            "separator": ".",
            "delimiter": "'"
        },
        "currency": {
            "format": {
                "unit": "SFr.",
                "code": "CHF",
                "format": "%u %n"
            }
        }
    }
});

//English - Global default actually similar to 'en_GB' since most english-speaking countries apply UK formatting
I18n.translations['en'] = $.extend(true, I18n.translations['en'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d-%b-%Y",
    //        "short": "%d/%m/%Y",
    //        "medium": "%d-%b-%Y",
    //        "long": "%d %B %Y",
    //        "full": "%A, %-d %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "/",
    //    "start_of_week": 1,
    //    "day_names": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    //    "abbr_day_names": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    //    "abbr_day_names_short": ["S", "M", "T", "W", "T", "F", "S"],
    //    "month_names": [0, "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    //    "abbr_month_names": [0, "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
    //    "abbr_month_names_short": [0, "J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": ","
        },
        "currency": {
            "format": {
                "unit": "£",
                "code": "GBP",
                "precision": 2,
                "format": "%u%n"
            }
        }
    }
});

//English - Australia
I18n.translations['en-AU'] = $.extend(true, I18n.translations['en-AU'] || {}, I18n.translations['en']); // Pick up all the properties from default 'en'
I18n.translations['en-AU'] = $.extend(true, I18n.translations['en-AU'] || {}, { // Override what is necessary
    //"time": {
    //    "formats": {
    //        "default": "%-I:%M %p"
    //    }
    //}
    "number": {
        "currency": {
            "format": {
                "unit": "$",
                "code": "AUD",
                "format": "%u%n"
            }
        }
    }
});

//English - Canada
I18n.translations['en-CA'] = $.extend(true, I18n.translations['en-CA'] || {}, I18n.translations['en']); // Pick up all the properties from default 'en'
I18n.translations['en-CA'] = $.extend(true, I18n.translations['en-CA'] || {}, { // Override what is necessary
    //"time": {
    //    "patterns": {
    //        "duration": "%-Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-I:%M %p"
    //    }
    //},
    //"date": {
    //    "formats": {
    //        "default": "%-d-%b-%Y",
    //        "medium": "%-d-%b-%Y",
    //        "long": "%B %-d, %Y",
    //        "full": "%A, %B %-d, %Y"
    //    },
    //    "start_of_week": 0
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "$",
                "code": "CAD"
            }
        }
    }
});

//English - Ireland
I18n.translations['en-IE'] = $.extend(true, I18n.translations['en-IE'] || {}, I18n.translations['en']); // Pick up all the properties from default 'en'
I18n.translations['en-IE'] = $.extend(true, I18n.translations['en-IE'] || {}, { // Override what is necessary
    //"date": {
    //    "formats": {
    //        "full": "%d %B %Y"
    //    }
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR"
            }
        }
    }
});

//English - India
I18n.translations['en-IN'] = $.extend(true, I18n.translations['en-IN'] || {}, I18n.translations['en']); // Pick up all the properties from default 'en'
I18n.translations['en-IN'] = $.extend(true, I18n.translations['en-IN'] || {}, { // Override what is necessary
    //"time": {
    //    "formats": {
    //        "default": "%-I:%M %p"
    //    }
    //},
    //"date": {
    //    "formats": {
    //        "default": "%-d %b, %Y",
    //        "short": "%-d/%-m/%Y",
    //        "medium": "%-d %b, %Y",
    //        "long": "%-d %B, %Y",
    //        "full": "%A, %-d %B, %Y"
    //    }
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "Rs.",
                "code": "INR"
            }
        }
    }
});

//English - US
I18n.translations['en-US'] = $.extend(true, I18n.translations['en-US'] || {}, I18n.translations['en']); // Pick up all the properties from default 'en'
I18n.translations['en-US'] = $.extend(true, I18n.translations['en-US'] || {}, { // Override what is necessary
    //"time": {
    //    "patterns": {
    //        "duration": "%-Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-I:%M %p"
    //    }
    //},
    //"date": {
    //    "formats": {
    //        "default": "%b %-d, %Y",
    //        "short": "%-m/%-d/%Y",
    //        "medium": "%b %-d, %Y",
    //        "long": "%B %-d, %Y",
    //        "full": "%A, %B %-d, %Y"
    //    },
    //    "format": "mmddyy",
    //    "separator": "/",
    //    "start_of_week": 0
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "$",
                "code": "USD",
                "format": "%u%n"
            }
        }
    }
});

//Spanish - Spain (Traditional)
I18n.translations['es'] = $.extend(true, I18n.translations['es'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d-%b-%Y",
    //        "short": "%-d/%m/%Y",
    //        "medium": "%d-%b-%Y",
    //        "long": "%-d de %B de %Y",
    //        "full": "%A %-d de %B de %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "/",
    //    "start_of_week": 1,
    //    "day_names": ["domingo","lunes","martes", "miércoles", "jueves", "viernes","sábado"],
    //    "abbr_day_names": ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    //    "abbr_day_names_short": ["D", "L", "M", "M", "J", "V", "S"],
    //    "month_names": [0, "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    //    "abbr_month_names": [0, "ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    //    "abbr_month_names_short": [0, "E", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//Spanish - Argentina
I18n.translations['es-AR'] = $.extend(true, I18n.translations['es-AR'] || {}, I18n.translations['es']); // Pick up all the properties from default 'es'
I18n.translations['es-AR'] = $.extend(true, I18n.translations['es-AR'] || {}, { // Override what is necessary
    //"date": {
    //    "formats": {
    //        "default": "%d/%m/%Y",
    //        "short": "%d/%m/%Y",
    //        "medium": "%d/%m/%Y"
    //    },
    //    "abbr_day_names": ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sab"]
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "$",
                "code": "ARS",
                "format": "%u%n"
            }
        }
    }
});

//Spanish - Mexico
I18n.translations['es-MX'] = $.extend(true, I18n.translations['es-MX'] || {}, I18n.translations['es']); // Pick up all the properties from default 'es'
I18n.translations['es-MX'] = $.extend(true, I18n.translations['es-MX'] || {}, { // Override what is necessary
    //"time": {
    //    "patterns": {
    //        "duration": "%-Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%I:%M %p"
    //    }
    //},
    //"date": {
    //    "formats": {
    //        "default": "%-d/%m/%Y",
    //        "medium": "%-d/%m/%Y"
    //    }
    //},
    "number": {
        "format": {
            "separator": ".",
            "delimiter": ","
        },
        "currency": {
            "format": {
                "unit": "$",
                "code": "MXN",
                "format": "%u%n"
            }
        }
    }
});

//Finnish
I18n.translations['fi'] = $.extend(true, I18n.translations['fi'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%-d.%-m.%Y",
    //        "short": "%-d.%-m.%Y",
    //        "medium": "%-d.%-m.%Y",
    //        "long": "%-d. %B %Y",
    //        "full": "%-d. %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": ".",
    //    "start_of_week": 1,
    //    "day_names": ["Sunnuntai", "Maanantai", "Tiistai", "Keskiviiko", "Torstai", "Perjantai", "Lauantai"],
    //    "abbr_day_names": ["su", "ma", "ti", "ke", "to", "pe", "la"],
    //    "abbr_day_names_short": ["S", "M", "T", "K", "T", "P", "L"],
    //    "month_names": [0, "tammikuu", "helmikuu", "maaliskuu", "huhtikuu", "toukokuu", "kesäkuu", "heinäkuu", "elokuu", "syyskuu", "lokakuu", "marraskuu", "joulukuu"],
    //    "abbr_month_names": [0, "tammi", "helmi", "maalis", "huhti", "touko", "kesä", "heinä", "elo", "syys", "loka", "marras", "joulu"],
    //    "abbr_month_names_short": [0, "T", "H", "M", "H", "T", "K", "H", "E", "S", "L", "M", "J"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": " "
        },
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//French - France
I18n.translations['fr'] = $.extend(true, I18n.translations['fr'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%-d %b %Y",
    //        "short": "%d/%m/%Y",
    //        "medium": "%-d %b %Y",
    //        "long": "%-d %B %Y",
    //        "full": "%A %-d %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "/",
    //    "start_of_week": 1,
    //    "day_names": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    //    "abbr_day_names": ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    //    "abbr_day_names_short": ["D", "L", "M", "M", "J", "V", "S"],
    //    "month_names": [0, "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
    //    "abbr_month_names": [0, "janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
    //    "abbr_month_names_short": [0, "j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
    //    "order": [":day", ":month", ":year"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": " "
        },
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//French - Belgium
I18n.translations['fr-BE'] = $.extend(true, I18n.translations['fr-BE'] || {}, I18n.translations['fr']); // Pick up all the properties from default 'fr'
I18n.translations['fr-BE'] = $.extend(true, I18n.translations['fr-BE'] || {}, { // Override what is necessary
    //"time": {
    //    "formats": {
    //        "default": "%-H:%M"
    //    }
    //},
    //"date": {
    //    "formats": {
    //        "default": "%b-%-d-%Y",
    //        "short": "%-d/%-m/%Y",
    //        "medium": "%b-%-d-%Y",
    //        "long": "%B %-d %Y",
    //        "full": "%A, %B %-d %Y"
    //    },
    //    "start_of_week": 1
    //}
    "number": {
        "format": {
            "delimiter": "."
        }
    }
});

//French - Canada
I18n.translations['fr-CA'] = $.extend(true, I18n.translations['fr-CA'] || {}, I18n.translations['fr']); // Pick up all the properties from default 'fr'
I18n.translations['fr-CA'] = $.extend(true, I18n.translations['fr-CA'] || {}, { // Override what is necessary
    //"date": {
    //    "formats": {
    //        "default": "%Y-%m-%d",
    //        "short": "%Y-%m-%d",
    //        "medium": "%Y-%m-%d"
    //    },
    //    "start_of_week": 0,
    //    "format": "yymmdd",
    //    "separator": "-"
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "$",
                "code": "CAD"
            }
        }
    }
});

//French - Switzerland
I18n.translations['fr-CH'] = $.extend(true, I18n.translations['fr-CH'] || {}, I18n.translations['fr']); // Pick up all the properties from default 'fr'
I18n.translations['fr-CH'] = $.extend(true, I18n.translations['fr-CH'] || {}, { // Override what is necessary
    //"date": {
    //    "formats": {
    //        "short": "%d.%m.%Y",
    //        "long": "%-d. %B %Y",
    //        "full": "%A, %-d. %B %Y"
    //    }
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": "'"
        },
        "currency": {
            "format": {
                "unit": "SFr.",
                "code": "CHF",
                "format": "%u %n"
            }
        }
    }
});

//Italian - Italy
I18n.translations['it'] = $.extend(true, I18n.translations['it'] || {},  {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default":"%-H.%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%-d-%b-%Y",
    //        "short": "%d/%m/%Y",
    //        "medium": "%-d-%b-%Y",
    //        "long": "%-d %B %Y",
    //        "full": "%A %-d %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "/",
    //    "start_of_week": 1,
    //    "day_names": ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"],
    //    "abbr_day_names": ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
    //    "abbr_day_names_short": ["d", "l", "m", "m", "g", "v", "s"],
    //    "month_names": [0, "gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
    //    "abbr_month_names": [0, "gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"],
    //    "abbr_month_names_short": [0, "g", "f", "m", "a", "m", "g", "l", "a", "s", "o", "n", "d"],
    //    "order": [":day", ":month", ":year"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR",
                "precision": 2,
                "format": "%u %n"
            }
        }
    }
});

//Italian - Switzerland
I18n.translations['it-CH'] = $.extend(true, I18n.translations['it-CH'] || {}, I18n.translations['it']); // Pick up all the properties from default 'it'
I18n.translations['it-CH'] = $.extend(true, I18n.translations['it-CH'] || {}, { // Override what is necessary
    //"time": {
    //    "formats": {
    //        "default":"%H:%M"
    //    }
    //},
    //"date": {
    //    "formats": {
    //        "short": "%d.%m.%Y",
    //        "long": "%-d. %B %Y",
    //        "full": "%A, %-d. %B %Y"
    //    }
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": "'"
        },
        "currency": {
            "format": {
                "unit": "SFr.",
                "code": "CHF"
            }
        }
    }
});

//Japanese
I18n.translations['ja'] = $.extend(true, I18n.translations['ja'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%Y/%m/%d",
    //        "short": "%Y/%m/%d",
    //        "medium": "%Y/%m/%d",
    //        "long": "%Y/%m/%d",
    //        "full": "%Y年%-m月%-d日"
    //    },
    //    "format": "yymmdd",
    //    "separator": "/",
    //    "start_of_week": 0,
    //    "day_names": ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    //    "abbr_day_names": ["日", "月", "火", "水", "木", "金", "土"],
    //    "abbr_day_names_short": ["日", "月", "火", "水", "木", "金", "土"],
    //    "month_names": [0, "一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    //    "abbr_month_names": [0, "一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    //    "abbr_month_names_short": [0, "一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    //    "meridian": ["午前", "午後"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": ","
        },
        "currency": {
            "format": {
                "unit": "¥",
                "code": "JPY",
                "precision": 0,
                "format": "%u %n"
            }
        }
    }
});


//Korean - Korea
I18n.translations['ko'] = $.extend(true, I18n.translations['ko'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%p %-I:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%Y. %-m. %-d",
    //        "short": "%Y. %-m. %-d",
    //        "medium": "%Y. %-m. %-d",
    //        "long": "%Y년 %-m월 %-d일 (%a)",
    //        "full": "%Y년 %-m월 %-d일 %A"
    //    },
    //    "format": "yymmdd",
    //    "separator": ".",
    //    "start_of_week": 1,
    //    "day_names": ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
    //    "abbr_day_names": ["일", "월", "화", "수", "목", "금", "토"],
    //    "abbr_day_names_short": ["일", "월", "화", "수", "목", "금", "토"],
    //    "month_names": [0, "일월", "이월", "삼월", "사월", "오월", "유월", "칠월", "팔월", "구월", "시월", "십일월", "십이월"],
    //    "abbr_month_names": [0, "일월", "이월", "삼월", "사월", "오월", "유월", "칠월", "팔월", "구월", "시월", "십일월", "십이월"],
    //    "abbr_month_names_short": [0, "일월", "이월", "삼월", "사월", "오월", "유월", "칠월", "팔월", "구월", "시월", "십일월", "십이월"],
    //    "meridian": ["오전", "오후"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": ","
        },
        "currency": {
            "format": {
                "unit": "₩",
                "code": "KRW",
                "precision": 0,
                "format": "%u %n"
            }
        }
    }
});

//Malay - Malaysia
I18n.translations['ms'] = $.extend(true, I18n.translations['ms'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-I:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d %B %Y",
    //        "short": "%d/%m/%Y",
    //        "medium": "%d %B %Y",
    //        "long": "%d %B %Y",
    //        "full": "%A %d %b %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "/",
    //    "start_of_week": 1,
    //    "day_names": ["Ahad", "Isnin", "Selasa", "Rabu", "Khamis", "Jumaat", "Sabtu"],
    //    "abbr_day_names": ["Ahd", "Isn", "Sel", "Rab", "Kha", "Jum", "Sab"],
    //    "abbr_day_names_short": ["1", "2", "3", "4", "5", "6", "7"],
    //    "month_names": [0, "Januari", "Februari", "Mac", "April", "Mei", "Jun", "Julai", "Ogos", "September", "Oktober", "November", "Disember"],
    //    "abbr_month_names": [0, "Jan", "Feb", "Mac", "Apr", "Mei", "Jun", "Jul", "Ogos", "Sep", "Okt", "Nov", "Dis"],
    //    "abbr_month_names_short": [0, "J", "F", "M", "A", "M", "J", "J", "O", "S", "O", "N", "D"],
    //    "meridian": ["AM", "PM"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": ","
        },
        "currency": {
            "format": {
                "unit": "RM",
                "code": "MYR",
                "precision": 2,
                "format": "%u %n"
            }
        }
    }
});


//Norwegian Bokmål (Norway)
I18n.translations['nb'] = $.extend(true, I18n.translations['nb'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d.%b.%Y",
    //        "short": "%d.%m.%Y",
    //        "medium": "%d.%b.%Y",
    //        "long": "%-d. %B %Y",
    //        "full": "%-d. %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": ".",
    //    "start_of_week": 1,
    //    "day_names": ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
    //    "abbr_day_names": ["søn.", "man.", "tir.", "ons.", "tor.", "fre.", "lør."],
    //    "abbr_day_names_short": ["s", "m", "t", "o", "t", "f", "l"],
    //    "month_names": [0, "januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"],
    //    "abbr_month_names": [0, "jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"],
    //    "abbr_month_names_short": [0, "j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
    //    "meridian": ["AM", "PM"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": " "
        },
        "currency": {
            "format": {
                "unit": "kr",
                "code": "NOK",
                "precision": 2,
                "format": "%u %n"
            }
        }
    }
});

//Dutch (Netherlands)
I18n.translations['nl'] = $.extend(true, I18n.translations['nl'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%-d-%b-%Y",
    //        "short": "%-d-%-m-%Y",
    //        "medium": "%-d-%b-%Y",
    //        "long": "%-d %B %Y",
    //        "full": "%A %-d %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "-",
    //    "start_of_week": 1,
    //    "day_names": ["zondag","maandag","dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
    //    "abbr_day_names": ["zo", "ma", "di", "wo", "do", "vr", "za"],
    //    "abbr_day_names_short": ["z", "m", "d", "w", "d", "v", "z"],
    //    "month_names": [0, "januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
    //    "abbr_month_names": [0, "jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
    //    "abbr_month_names_short": [0, "j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR",
                "precision": 2,
                "format": "%u %n"
            }
        }
    }
});

//Dutch (Belgium)
I18n.translations['nl-BE'] = $.extend(true, I18n.translations['nl-BE'] || {}, I18n.translations['nl']); // Pick up all the properties from default 'nl'
I18n.translations['nl-BE'] = $.extend(true, I18n.translations['nl-BE'] || {}, { // Override what is necessary
    //"date": {
    //    "formats": {
    //        "short": "%-d/%m/%Y"
    //    }
    //},
    "number": {
        "format": {
            "delimiter": "."
        },
        "currency": {
            "format": {
                "format": "%n %u"
            }
        }
    }
});

//Portuguese (Portugal)
I18n.translations['pt'] = $.extend(true, I18n.translations['pt'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%-d/%b/%Y",
    //        "short": "%d-%m-%Y",
    //        "medium": "%-d/%b/%Y",
    //        "long": "%-d de %B de %Y",
    //        "full": "%A, %-d de %B de %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "-",
    //    "start_of_week": 1,
    //    "day_names": ["Domingo","Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    //    "abbr_day_names": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    //    "abbr_day_names_short": ["D", "S", "T", "Q", "Q", "S", "S"],
    //    "month_names": [0, "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    //    "abbr_month_names": [0, "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    //    "abbr_month_names_short": [0, "j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "€",
                "code": "EUR",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//Polish - Poland
I18n.translations['pl'] = $.extend(true, I18n.translations['pl'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%Y-%m-%d",
    //        "short": "%d.%m.%Y",
    //        "medium": "%Y-%m-%d",
    //        "long": "%-d %B %Y",
    //        "full": "%A, %-d %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": ".",
    //    "start_of_week": 1,
    //    "day_names": ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"],
    //    "abbr_day_names": ["ni", "po", "wt", "śr", "cz", "pi", "so"],
    //    "abbr_day_names_short": ["N", "P", "W", "Ś", "C", "P", "S"],
    //    "month_names": [0, "styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"],
    //    "abbr_month_names": [0, "stycz.", "luty", "mar.", "kwiec.", "maj", "czerw.", "lip.", "sierp.", "wrzes.", "pazdz.", "listop.", "grudz."],
    //    "abbr_month_names_short": [0, "S", "L", "M", "K", "M", "C", "L", "S", "W", "P", "L", "G"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": " "
        },
        "currency": {
            "format": {
                "unit": "zł",
                "code": "PLN",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//Swedish - Sweden
I18n.translations['sv'] = $.extend(true, I18n.translations['sv'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%It %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%Y-%b-%d",
    //        "short": "%Y-%m-%d",
    //        "medium": "%Y-%b-%d",
    //        "long": "den %-d %B %Y",
    //        "full": "den %-d %B %Y"
    //    },
    //    "format": "yymmdd",
    //    "separator": "-",
    //    "start_of_week": 1,
    //    "day_names": ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"],
    //    "abbr_day_names": ["sön", "mån", "tis", "ons", "tors", "fre", "lör"],
    //    "abbr_day_names_short": ["s", "m", "t", "o", "t", "f", "l"],
    //    "month_names": [0, "januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"],
    //    "abbr_month_names": [0, "jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sept", "okt", "nov", "dec"],
    //    "abbr_month_names_short": [0, "j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": " "
        },
        "currency": {
            "format": {
                "unit": "kr",
                "code": "SEK",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//Thai (Thailand)
I18n.translations['th'] = $.extend(true, I18n.translations['th'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%-I:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%-d %b %Y",
    //        "short": "%-d/%-m/%Y",
    //        "medium": "%-d %b %Y",
    //        "long": "%-d %B %Y",
    //        "full": "%A %-d %B %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "/",
    //    "start_of_week": 1,
    //    "day_names": ["วันอาทิตย์", "วันจันทร์", "วันอังคาร", "วันพุธ", "วันพฤหัสบด", "วันศุกร์", "วันเสาร์"],
    //    "abbr_day_names": ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
    //    "abbr_day_names_short": ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
    //    "month_names": [0, "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
    //    "abbr_month_names": [0, "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
    //    "abbr_month_names_short": [0, "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
    //    "meridian": ["ก่อนเที่ยง", "หลังเที่ยง"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": ","
        },
        "currency": {
            "format": {
                "unit": "฿",
                "code": "THB",
                "precision": 2,
                "format": "%u %n"
            }
        }
    }
});

//Turkish - Turkey
I18n.translations['tr'] = $.extend(true, I18n.translations['tr'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d.%b.%Y",
    //        "short": "%d.%m.%Y",
    //        "medium": "%d.%b.%Y",
    //        "long": "%d %B %Y %A",
    //        "full": "%d %B %Y %A"
    //    },
    //    "format": "ddmmyy",
    //    "separator": ".",
    //    "start_of_week": 1,
    //    "day_names": ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
    //    "abbr_day_names": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    //    "abbr_day_names_short": ["S", "M", "T", "W", "T", "F", "S"],
    //    "month_names": [0, "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
    //    "abbr_month_names": [0, "Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
    //    "abbr_month_names_short": [0, "J", "F", "M", "A", "M", "J", "T", "A", "S", "O", "N", "D"],
    //    "meridian": ["am", "pm"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "TL",
                "code": "TRL",
                "precision": 2,
                "format": "%n %u"
            }
        }
    }
});

//Vietnamese (Vietnam)
I18n.translations['vi'] = $.extend(true, I18n.translations['vi'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%H:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats": {
    //        "default": "%d-%m-%Y",
    //        "short": "%d/%m/%Y",
    //        "medium": "%d-%m-%Y",
    //        "long": "Ngày %d tháng %-m năm %Y",
    //        "full": "%A, ngày %d %B năm %Y"
    //    },
    //    "format": "ddmmyy",
    //    "separator": "-",
    //    "start_of_week": 1,
    //    "day_names": ["Chủ nhật", "Thứ hai", "Thứ ba", "Thứ tư", "Thứ năm", "Thứ sáu", "Thứ bảy"],
    //    "abbr_day_names": ["CN", "Th 2", "Th 3", "Th 4", "Th 5", "Th 6", "Th 7"],
    //    "abbr_day_names_short": ["CN", "Th 2", "Th 3", "Th 4", "Th 5", "Th 6", "Th 7"],
    //    "month_names": [0, "tháng một", "tháng hai", "tháng ba", "tháng tư", "tháng năm", "tháng sáu", "tháng bảy", "tháng tám", "tháng chín", "tháng mười", "tháng mười một", "tháng mười hai"],
    //    "abbr_month_names": [0, "thg 1", "thg 2", "thg 3", "thg 4", "thg 5", "thg 6", "thg 7", "thg 8", "thg 9", "thg 10", "thg 11", "thg 12"],
    //    "abbr_month_names_short": [0, "thg 1", "thg 2", "thg 3", "thg 4", "thg 5", "thg 6", "thg 7", "thg 8", "thg 9", "thg 10", "thg 11", "thg 12"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ",",
            "delimiter": "."
        },
        "currency": {
            "format": {
                "unit": "₫",
                "code": "VND",
                "precision": 0,
                "format": "%n %u"
            }
        }
    }
});

//Chinese (China)
I18n.translations['zh'] = $.extend(true, I18n.translations['zh'] || {}, {
    //"time": {
    //    "patterns": {
    //        "ampm": "%-I:%M%p",
    //        "duration": "%Ih %Mm"
    //    },
    //    "formats": {
    //        "default": "%p%-I:%M"
    //    }
    //},
    //"date": {
    //    "patterns": {
    //        "small": "%b %-d",
    //        "medium": "%a, %b %-d",
    //        "large": "%a, %b %-d, %Y"
    //    },
    //    "formats":
    //        "default": "%Y-%-m-%-d",
    //        "short": "%Y-%-m-%-d",
    //        "medium": "%Y-%-m-%-d",
    //        "long": "%Y年%-m月%-d日",
    //        "full": "%Y年%-m月%-d日 %A"
    //    },
    //    "format": "yymmdd",
    //    "separator": "-",
    //    "start_of_week": 1,
    //    "day_names": ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    //    "abbr_day_names": ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    //    "abbr_day_names_short": ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    //    "month_names": [0, "一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    //    "abbr_month_names": [0, "一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    //    "abbr_month_names_short": [0, "一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
    //    "meridian": ["上午", "下午"]
    //},
    "number": {
        "format": {
            "precision": 2,
            "separator": ".",
            "delimiter": ","
        },
        "currency": {
            "format": {
                "unit": "￥",
                "code": "CNY",
                "precision": 2,
                "format": "%u%n"
            }
        }
    }
});

//Chinese (Hong Kong)
I18n.translations['zh-HK'] = $.extend(true, I18n.translations['zh-HK'] || {}, I18n.translations['zh']); // Pick up all the properties from default 'zh'
I18n.translations['zh-HK'] = $.extend(true, I18n.translations['zh-HK'] || {}, { // Override what is necessary
    //"date": {
    //    "formats": {
    //        "default": "%Y年%-m月%-d日",
    //        "short": "%Y年%-m月%-d日",
    //        "medium": "%Y年%-m月%-d日",
    //        "long": "%Y年%m月%d日 %A",
    //        "full": "%Y年%m月%d日 %A"
    //    },
    //    "abbr_day_names": ["日", "一", "二", "三", "四", "五", "六"],
    //    "abbr_day_names_short": ["日", "一", "二", "三", "四", "五", "六"]
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "HK$",
                "code": "HKD"
            }
        }
    }
});

//Chinese (Taiwan)
I18n.translations['zh-TW'] = $.extend(true, I18n.translations['zh-TW'] || {}, I18n.translations['zh']); // Pick up all the properties from default 'zh'
I18n.translations['zh-TW'] = $.extend(true, I18n.translations['zh-TW'] || {}, { // Override what is necessary
    //"time": {
    //    "formats": {
    //        "default": "%p %-I:%M"
    //    }
    //},
    //"date": {
    //    "formats": {
    //        "default": "%Y/%-m/%-d",
    //        "medium": "%Y/%-m/%-d"
    //    },
    //    "separator": "/"
    //},
    "number": {
        "currency": {
            "format": {
                "unit": "NT$",
                "code": "TWD"
            }
        }
    }
});
