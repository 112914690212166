window.uitk = (function($, bootstrapped) {

    "use strict";

    var init,
        readyState = false, //test for ready state by running "typeof uitk!='undefined' && uitk.readyState" (false if not ready, true when ready)
        hasWin8Touch = Modernizr.win8touch,
        isTouchDevice = (Modernizr.touch || hasWin8Touch),
        locale,
        $body = $('body'),
        eventNames = {
            transitionEnd: 'transitionend'
        },
        focusableElement = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
        adapt,
    //methods
        pubsub,
        validate,
        cookieHandling,
        videoHelpers,
        createUniqueId,
        getCompiledTemplate,
        throttledEvents,
        Logger,
        corePath,
        prepFields,
        focusElement,
        isFocusableElement,
        liveAnnounce,
        removeHtmlInString,
        escapeSpecialRegexChars,
        egenciaFetch,
        redirectToLogin,
        getFeedbackSurveyUrl,
        gamAdInvocation,
        elem = document.createElement('textarea');

    //code to be run on page load
    init = function(){
        // Debounced version of window resize event
        var resizeTimeout;
        $(window).on('resize', function () {
            if (resizeTimeout) clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(function () {
                uitk.publish('debounced.resize');
            }, 100);
        });

        // Init event throttle
        throttledEvents();

        // Set readystate to true
        uitk.readyState = true;
        
        // Log info (remove the check post-IE9) 
        console.log('-- UITK Info --\nBOM version: ' + uitk.version + '\nAssets url: ' + uitk.hostedAssetsUrl + ' (note that asset version may differ from BOM, this is expected)');

    };


    createUniqueId = (function(){
        var idNum = 0;

        return function(){

            idNum++;

            return 'uitkId'+idNum;

        };
    }());

    //PUB SUB (private methods)
    pubsub = {

        topics: {},
        lastToken: 0,

        // Create or retrieve a topic
        // @param topic The topic to create or retrieve
        topic: function (topic) {
            var topics = this.topics;

            //create topic if it doesn't already exist
            if (!topics[topic]) {
                topics[topic] = [];
            }

            return topics[topic];
        },

        // Publish a topic
        // @param topic The topic to subscribe to
        // @param {args} Additional arguments are passed to the subscriber's callback
        publish: function (topic) {
            var subs = pubsub.topics[topic],
                argLength = arguments.length,
                subsLength,
                data = [],
                sub,
                context,
                callback,
                i;

            //check to see if there are any subscriptions
            if (subs) {
                //put arguments in to an array
                for (i = 0; i < argLength; i = i + 1) {
                    data.push(arguments[i]);
                }

                //loop through subscriptions passing the arguments to the callbacks
                //Using i-1 rather the i+1 so that an error isn't thrown if unsubscribe is used with a callback
                subsLength = subs.length;
                for (i = subsLength - 1; i >= 0; i = i - 1) {
                    sub = subs[i];
                    context = sub.context;
                    callback = sub.callback;

                    //run callback
                    if (typeof callback === 'function') {
                        callback.apply(context, data);
                    }
                }
            }
        },

        // Subscribe to a topic
        // @param {topic} The topic to subscribe to
        // @param {context} (optional) The value of this provided to the callbacks
        subscribe: function (topic, context) {
            var argLength = arguments.length,
                callback,
                subs,
                argNum = 2,
                callbackContext = context,
                token = pubsub.lastToken++,
                i;

            if (typeof context === 'function') {
                callbackContext = null;
                argNum = 1;
            }

            //subscribe each callback
            if (arguments.length > argNum) {
                for (i = argNum; i < argLength; i = i + 1) {
                    callback = arguments[i];

                    if (typeof callback === 'function') {
                        subs = pubsub.topic(topic);
                        subs.push({context: callbackContext, callback: arguments[i], token: token.toString()});
                    }
                }
            }

            return token.toString();
        },

        // Unsubscribe from a topic
        // @param topic The topic to unsubscribe from
        // @param {args} Additional arguments are callbacks to be unsubscribed from the topic - add as many callbacks as you like.
        //               if no additional arguments are provided the all subscriptions to the topic will be unsubscribed
        unsubscribe: function (topic) {
            var subs = pubsub.topics[topic],
                subsLength,
                argLength = arguments.length,
                callbackOrToken,
                i, s;

            //subscribe each callback
            if (argLength > 1 && subs) {

                for (i = 1; i < argLength; i = i + 1) {
                    subsLength = subs.length;
                    callbackOrToken = arguments[i];

                    if (typeof callbackOrToken === 'function') {
                        for (s = subsLength - 1; s >= 0; s = s - 1) {
                            if (subs[s].callback === callbackOrToken) {
                                subs.splice(s, 1);
                            }
                        }
                    } else if (typeof callbackOrToken === 'string') {
                        for (s = subsLength - 1; s >= 0; s = s - 1) {
                            if (subs[s].token === callbackOrToken) {
                                subs.splice(s, 1);
                            }
                        }
                    }
                }
            } else if (argLength === 1 && subs) {
                delete pubsub.topics[topic];
            }
        }
    };

    // Bind click on X in the text inputs
    $body.on('click', '.input-clear', function(e) {
        var input = $(this).siblings('input')[0];
        input.value = "";
        uitk.publish('input.clear', {id: input.id});
    });

    //PREP FIELDS
    //used to prepare fields that are added to the page after page load
    prepFields = function () {
        uitk.initCalendarInputs();
        uitk.initStepper();
    };


    //COOKIE HANDLING (private methods)
    //based on http://www.quirksmode.org/js/cookies.html
    cookieHandling = {
        //GET EXPIRY DATE STRING
        getFutureDateAsString: function (baseDate, days) {
            baseDate.setTime(baseDate.getTime() + (days * 24 * 60 * 60 * 1000));

            return (baseDate.toGMTString());
        },

        //CREATE COOKIE
        /**
         * @param {String} name - Name of cookie for retrieval at a later date
         * @param {String} value - Value to store in the cookie
         * @param {Number} [days] - Number of days before the cookie expires. If days is < 0 the cookie will be deleted
         * @param {String} [domain=window.location.hostname] - The domain to create or delete a cookie on. (Note: you can only create cookies on your domain or subdomain)
         */
        createCookie: function (name, value, days, domain, insecure) {
            let expires;
            let dateString;
            //generate the expiry date or set to "" if no days are given
            //a cookie without an expiry date will expire at the end of the session
            if (days) {
                dateString = uitk.getFutureDateAsString(new Date(), days);
                expires = '; expires=' + dateString;
            } else {
                expires = '';
            }

            const domainStr = domain ? '; domain=' + domain : '';
            const secure = insecure === true ? "" : ";secure";

            //create cookie
            // We want the cookie stored to have all special chearacters escaped
            // because the server expects them to be. The value reported can be what
            // the client expects
            document.cookie = `${name}=${cookieHandling.formatCookie(value)}${expires};path=/${domainStr}${secure}`;

            //publish cookie.deleted or cookie.created along with object containing cookie's properties
            if (days && days < 0) {
                uitk.publish('cookie.deleted', name, domain || window.location.hostname);
            } else {
                uitk.publish('cookie.created', {
                    name,
                    value,
                    days,
                    domain: domain || window.location.hostname,
                    expires: dateString
                });
            }
        },

        //READ COOKIE
        readCookie: function (name) {
            var nameEQ = name + "=",
                cookieArray = document.cookie.split(';'),
                cookieArrayLength = cookieArray.length,
                i = 0,
                currentCookie;

            //loop through all cookies
            for (i = 0; i < cookieArrayLength; i++) {
                //select a cookie
                currentCookie = cookieArray[i];

                //remove any spaces from the front of the currently selected cookie
                while (currentCookie.charAt(0) === ' ') {
                    currentCookie = currentCookie.substring(1, currentCookie.length);
                }

                //check if the current cookie is the one requested by checking
                //if the name with equals sign is at index 0
                if (currentCookie.indexOf(nameEQ) === 0) {
                    //return the value of the cookie
                    return decodeURIComponent(currentCookie.substring(nameEQ.length, currentCookie.length)); // decodeURIComponent decodes more stuff than encodeURIComponent
                }
            }

            //if cookie isn't found return null
            return null;
        },

        //DELETE COOKIE
        /**
         * @param {String} name - name of the cookie to delete
         * @param {String} domain - domain of the cookie to delete (Note: you can only delete cookies on your domain or subdomain)
         */
        deleteCookie: function (name, domain) {
            //set the cookie to have a life span of -1 days in order to remove it
            uitk.createCookie(name, '', -1, domain);
        },

        // FORMAT COOKIE
        formatCookie: function (cookieStr) {
            var encodedCookie; 
            encodedCookie = encodeURIComponent(cookieStr);                          // encode the cookie
            encodedCookie = encodedCookie.replace(/\(/g, "%28").replace(/\)/g, "%29");  // guest arrangees last name is set to '(GUEST)' and encodeURIComponent doesn't escape '(' and ')'
            return encodedCookie;
        }

    };

    videoHelpers = {
        publishState: function (state, videoType, videoId) {
            var topic = 'media.' + state;

            uitk.publish(topic, videoType, videoId);
        },

        embedVideo: function (type) {
            var args = arguments,
                videoEmbed;

            switch (type) {
                case 'youtube':
                    videoEmbed = videoHelpers.embedYoutube(args[1], args[2], args[3], args[4]);
                    break;
                default:
                    return;
            }

            return videoEmbed;
        },

        loadYoutubeApi: function () {
            //make a defer
            uitk.youtubeApiDefer = $.Deferred();

            var url = "//www.youtube.com/iframe_api",
                tag = document.createElement('script'),
                firstScriptTag = document.getElementsByTagName('script')[0];

            //add source url to new script tag
            tag.src = "//www.youtube.com/iframe_api";

            //add tag to page
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },

        embedYoutube: function (videoId, videoOptions, id, playerReady) {

            var player,
                defaults,
                def = $.Deferred(),
                onStateChange,
                onError;

            if (!uitk.youtubeApiDefer) {
                videoHelpers.loadYoutubeApi();
            }

            defaults = {
                autohide: 1, //hide the video controls after video begins
                showinfo: 1, //show title of video on mouse over
                fs: 1, //show fullscreen button (only in flash player)
                modestbranding: 1, //hide the YouTube logo
                rel: 0, //stop related videos displaying at the end
                theme: 'light', //use the 'light' theme
                enablejsapi: 1
            };


            if (videoOptions) {
                videoOptions = $.extend(true, {}, defaults, videoOptions);
            } else {
                videoOptions = defaults;
            }

            onStateChange = function (data) {
                var state = data.data;

                switch (state) {
                    case 1:
                        //started
                        videoHelpers.publishState('started', 'youtube', videoId);
                        break;
                    case 0:
                        //ended
                        videoHelpers.publishState('ended', 'youtube', videoId);
                        break;
                    default:
                    //don't do anything
                }
            };

            //wait for youtube api to be ready
            uitk.youtubeApiDefer.done(function () {
                player = new YT.Player(id, {
                    height: '',
                    width: '',
                    videoId: videoId,
                    playerVars: videoOptions,
                    events: {
                        onReady: playerReady,
                        onStateChange: onStateChange
                    }
                });

                //resolves the defer passing the video player as an argument
                def.resolve(player);
            });

            //returns a defer. The player can't be deferred directly as it isn't built until
            //the api is ready
            return def.promise();
        },

        createVideoObject: function (mediaType, videoObj) {
            var video;

            switch (mediaType) {
                case 'youtube':
                    video = {
                        videoObj: videoObj,
                        type: mediaType,
                        playVideo: function () {
                            videoObj.playVideo();
                        },
                        rewindVideo: function () {
                            if (videoObj.getPlayerState() !== 5) {
                                videoObj.seekTo(0, true);
                            }
                        },
                        pauseVideo: function () {
                            videoObj.pauseVideo();
                        },
                        stopVideo: function () {
                            videoObj.stopVideo();
                        }
                    };
                    break;
                default:
                    video = {};
            }

            return video;
        }
    };

    // Returns a compiled template
    getCompiledTemplate = function (templateId) {
        var tmpls = Handlebars.templates;
        if (tmpls[templateId]) {
            return tmpls[templateId];
        }
        else if (tmpls["partials/uitk/" + templateId]) {
            return tmpls["partials/uitk/" + templateId];
        }
        else {
            console.log('ERROR: No template found for: ' + templateId);
        }
    };
    
    //Throttled Events
    throttledEvents = function () {
        var resizeThreshold = 100,
            resizeTimeout,
            lastResize;


        $(window).on('resize', function () {
            var now = new Date().getTime();

            if (lastResize && now < lastResize + resizeThreshold) {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(function () {
                    uitk.publish('throttled.resize');
                    lastResize = now;
                }, resizeThreshold);
            } else {
                uitk.publish('throttled.resize');
                lastResize = now;
            }
        });
    };

    // API for logging app errors with a custom message
    function _log(level, message, stacktrace, headers) {
        if(!stacktrace){
            try { //gets the stacktrace for current execution (same domain scripts only)
                throw new Error();
            } catch(e){
                stacktrace = e.stack;
            }
        }
        const url = (EG.contextPath ?? "").replace(/\/$/, '') + '/logger/' + level;
        const data = {
            message: message || "No message was provided.",
            stacktrace,
            page_url: window.location.href,
            page_title: document.title,
            user_agent: navigator.userAgent,
            referrer_url: document.referrer
        };

        // Log to console also
        (level === 'error') ? console.error(message, data) : console.log(message, data);

        // Log to Kibana proxy
        $.ajax({
            type: "POST",
            beforeSend: function(request) {
                if (headers) {
                    for (const property in headers) {
                        request.setRequestHeader(property, headers[property]);
                    }
                }
            },
            url: url,
            data: data
        });
    }
    Logger = {
        info: _log.bind(this, "info"),
        warn: _log.bind(this, "warn"),
        error: _log.bind(this, "error"),
        _log
    };

    // Similar to UitkAdapt.java. Merges with bootstrapped values from an instance of UitkAdaptModel.java
    adapt = {
        // Returns the native app action url
        getNativeActionUrl: function (action) {
            var mappedAction = '';

            if (this.iOSPlatform && this.iOSActions[action]) {
                mappedAction = this.iOSActions[action];
            }
            else if (this.androidPlatform && this.androidActions[action]) {
                mappedAction = this.androidActions[action];
            }

            return mappedAction;
        }
    };

    // utility function to return the current path to static assets
    corePath = function() {
        return EG.contextPath + "/";
    };

    isFocusableElement = function($elem){
        // need to check $elem isn't the document (a non focusable element) before checking if it is a focusable element
        // to stop "TypeError: a.getAttributeNode is not a function" in Firefox - https://jira/jira/browse/CSE-1088
        if (!$elem.is(document) && $elem.is(focusableElement)) {
            return true;
        }
        return false;
    };

    // utility function for adding messages to a hidden aria-live region
    liveAnnounce = function(msg, level){
        var $liveRegion = $('#uitk-live-announce');
        level = level || 'assertive';

        // first make sure the aria-live region exists
        if ($liveRegion.length > 0) {
            $liveRegion.empty('');
            $liveRegion.attr('aria-live', level);
            if (typeof msg === 'string') {
                $('<p>').text(msg).appendTo($liveRegion);
            } else {
                msg.clone().appendTo($liveRegion);
            }
            return $liveRegion;
        } else {
            return false;
        }
    };

    // Utility function for remove HTML tags from a string.
    // Param input      must be a string.
    // Return           string with our any HTML markups.
     removeHtmlInString = function (input) {
        input = !input || typeof input !== 'string' ? '' : input;
        return input.replace(/<\/?.*?>/gi, '');
    };

    // Utility function for escape regex special charactor
    escapeSpecialRegexChars = function(input) {
        return input.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'); // we have to escape regex pattern matching characters so users can search for them
    };

    // Add Egencia specific features to natvie fetch
    // - Standard Egencia Headers
    // - Server response exception
    // - 401 response redirects user to login
    // - timeout
    //
    // Options param can be used as native fetch options but has additional 'timeout'
    // param which expects a number of milliseconds.
    egenciaFetch = function( url, options = {}) {
        const {timeout, ...fetchOptions} = options;

        // Set-up the abort controller for cancelling the fetch request
        if(timeout) {
            const abortController = new AbortController();
            setTimeout(() => abortController.abort(), timeout);
            fetchOptions.signal = abortController.signal;
        }

        // Adding standard egencia headers for log tracking
        fetchOptions.headers = { ...fetchOptions.headers, ...EG.commonClientHeaders}

        return fetch(url, fetchOptions)
            .then(response => {
                if (!response.ok) {
                    // Redirect to login if user creds timedout
                    if (response.status == 401) {
                        uitk.utils.redirectToLogin();
                        return response;
                    }
                    // Otherwise just throw an exception
                    return Promise.reject(response);
                }
                return response;
            });
    };

    // 'local' redirect function pulled our for better unit test and a more pure funciton
    redirectToLogin = function() {
        window.location.href = "/auth/v1/login";
    };

    // For feedback and NPS survey
    getFeedbackSurveyUrl = function(options) {
        const userId = EG?.user?.userId?? "" ; // User id or "TUID"
        const sessionId = uitk?.readCookie('MC1') ? uitk.readCookie('MC1').replace('GUID\x3d', "") : "" ; // AKA "GUID" for eMain : "";
        const companyId = EG?.user?.companyId?? "" ; // Company id
        const locale = (EG?.currentLocale?? "");
        const roleType = EG?.user?.roleType?? "" ; // User role
        const pageName = EG?.pageName?? "";
        const appName = EG?.appName?? "";
        const abTestIds = getAbTestIds();
        const glassboxSessionCookie = uitk?.readCookie('_cls_s')?? "";
        const domain = window.location.hostname;

        const surveys = {
            "global": "7BE53177163850A9",
            "prompt": "7BE531772BE7AD97",
        }

        const metaData = [
          `prop11=${userId}`,
          `prop12=${sessionId}`,
          `prop17=${companyId}`,
          `prop30=${locale}`,
          `prop55=${roleType}`,
          `eVar55=${roleType}`,
          `listvar1=${abTestIds}`,
          `domain=${domain}`,
          `pagename=${pageName}`,
          `appname=${appName}`,
          `_cls_s=${glassboxSessionCookie}`
        ]

        const surveyBaseUrl = `https://survey.vovici.com/se/`
        const surveyContext = (options?.context && typeof options?.context === "string")? `${surveys[options.context]}/`: `${surveys["global"]}/`;
        const surveyLocale = `lang/${locale}`
        const surveyMetaData = metaData.reduce((accumulator,currentValue,currentIndex) => {
            return currentIndex === 1 ? `?${accumulator}` + `&${currentValue}` : accumulator + `&${currentValue}`
        })

        return `${surveyBaseUrl}${surveyContext}${surveyLocale}${surveyMetaData}`

        function getAbTestIds() {
            return Object.values(EG.analytics.configs)
                .map(config =>
                    (config && config.abTestId && config.bucket)
                        ? config.abTestId + '.' + config.bucket
                        : '')
                .filter(x => x)
                .join('|');
        }
    }

    /**
     * gamAdInvocation - Takes an array of ad "slots" and calls the google Ads API functions to render them.
     * @param adsAr - (Required) Array of objects in the form below:
     *
     *    {
     *      height: Number,
     *      width: Number,
     *      path: String,  (defined by ad team)
     *      targeting: Object, (defined by ad team)
     *      id: String,
     *     }
     *
     * @param callback - (Optional) function - Called if ad content was displayed
     * @param parentContainerId - (Optional) string - will un-hide the corresponding element. GAM will automatically hide/show
     * individual ad content containers but not any outer containers it doesn't know about. For example, we dont want to
     * show "Advertisement" label above the area if there was no ad shown.
     *
     */
    gamAdInvocation = function(adsAr = [], callback, parentContainerId) {
        if (!window.googletag) {
            window.googletag = {};
        }
        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(function () {
            googletag.destroySlots();
            const slot_count = adsAr.reduce((acc, ad) => {
                let slot;
                try {
                    slot = googletag.defineSlot(ad.path, [ad.width, ad.height], ad.id);
                } catch (err) {
                    Logger.error('UITK: googletag.defineSlot failed for ad, ', err);
                }
                if (slot) {
                    if (ad.targeting) {
                        slot.updateTargetingFromMap(ad.targeting);
                    }
                    slot.addService(googletag.pubads());
                    acc++;
                }
                return acc;
            }, 0);

            if (slot_count > 0) {
                // Convenience function for hiding/showing external content based on ad loading e.g. "Advertisement" label
                googletag.pubads().addEventListener('slotOnload', event => {
                    document.getElementById(parentContainerId)?.classList?.remove('hidden');
                });

                googletag.pubads().enableSingleRequest();
                googletag.enableServices();
                googletag.pubads().collapseEmptyDivs();

                adsAr.forEach(ad => {
                    googletag.display(ad.id);
                })
                if (callback) {
                    callback();
                }
            }
        });
    };

    var api = {
        init: init,
        readyState: readyState,
        locale: locale,

        //PUB SUB (public method)
        publish: pubsub.publish,
        subscribe: pubsub.subscribe,
        unsubscribe: pubsub.unsubscribe,

        //VALIDATION LIBRARY
        validate: validate,

        //COOKIE HANDLING
        getFutureDateAsString: cookieHandling.getFutureDateAsString,
        createCookie: cookieHandling.createCookie,
        readCookie: cookieHandling.readCookie,
        deleteCookie: cookieHandling.deleteCookie,

        //PREP FORM
        prepFields: prepFields,

        //VIDEO HELPERS
        embedVideo: videoHelpers.embedVideo,
        createVideoObject: videoHelpers.createVideoObject,

        //CREATE UNIQUE ID
        createUniqueId: createUniqueId,

        //IS TOUCH DEVICE (includes windows 8)
        isTouchDevice: isTouchDevice,

        //GET COMPILED HANDLEBARS TEMPLATE
        getCompiledTemplate: getCompiledTemplate,

        //logError
        corePath: corePath,

        //create object to expose modules
        modules: {},

        // For feedback and NPS survey (DirectWord)
        getFeedbackSurveyUrl,

        //create object to expose utilities
        utils: {
            //setting focus
            focusElement: focusElement,
            isFocusableElement:isFocusableElement,
            liveAnnounce: liveAnnounce,
            removeHtmlInString: removeHtmlInString,
            escapeSpecialRegexChars: escapeSpecialRegexChars,
            egenciaFetch,
            redirectToLogin,
            gamAdInvocation,
        },
        adapt: adapt,
        logger: Logger,
        // Event Names
        topics: eventNames,
    };
    //end UI Toolkit

    // Merge bootstrapped and API
    return $.extend(true, bootstrapped, api);

})(jQuery, window.uitk ?? {});
