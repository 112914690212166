window.uitk = ((uitk) => {
  const elem = document.createElement("textarea");
  
  function htmlUnescape(string) {
    elem.innerHTML = string;
    return elem.value;
  };

  // Support for Intl.MessageFormat() to "escape" html tags
  const HTML_TAG_REGEX = /<\/?\w+((\s+\w+(=(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)\/?>/g; // this is meant to match individual tag strings, not pairs
  const HTML_TAG_REPLACE_REGEX = /(\|REPLACED\|)/g;
  function getTagReplaceMatchers() {
      let removedHtml = []; // removed html is stored in the closure here.
      return [
        (m) => { // for removing html
          removedHtml.push(m);
          return `|REPLACED|`; // arbitrary replacement token
        },
        () => removedHtml.shift() // for adding it back
      ]
  }

  function transformLocaleName (locale) {
    return locale.replace(/_/g, '-');
  }

  function getSpacedSuffix(locale, suffix) {
    locale = locale || EG.currentLocale;
    const localeParentString = locale.substring(0, 2);
    const getSpacedLocale = new Set();
    const spacedLocale = [
      'cs',
      'de',
      'en-CA',
      'en-GB',
      'en-IE',
      'es',
      'fi',
      'fr',
      'it',
      'nb',
      'nl',
      'pl',
      'zh'
    ];

    // Did because IE11 doesn't support new Set(iterable)
    spacedLocale.forEach(x => getSpacedLocale.add(x));

    if (getSpacedLocale.has(localeParentString)) {
      return ' ' + suffix;
    } else {
      return suffix;
    }
  };

  uitk.i18n = {
    // Localizes messages
    msg: function () {
      const messageKey = arguments[0];
      let options = {};
      const len = arguments.length - 1;
      if(arguments.length > 1){
        options = arguments[len];
      }
      let message;

      for (var i = 1; i < len; i++) {
        options[(i - 1)] = arguments[i];
      }
      
      for(let key in options){ //remap incase they supplied params like "arg0", convert to "0";
        if(/arg/.test(key)){
          options[key.replace(/arg/g, "")] = options[key];
          console.warn("Don't use 'arg' when passing in numbered arguments");
        }
      }

      if (options.locale) {
        options.locale = transformLocaleName(options.locale);
      }

      // I18n.t() will handle invalid locales, which is what we want, but it doesn't handle missing keys
      // We can't just check for an existing bundle before checking for a missing key because there are many cases where the bundle will be missing and that's acceptable (we don't include everything from all the locales the user doesn't need)

      const locale = options.locale ?? EG.currentLocale;
      const translation = I18n.translations[locale]?.[messageKey];

      if (translation) {
        // It may be confusing to see two html escape related operations here.
        // This first call is to unescape HTML chars such as &lt; and &gt;.
        // mainly kept for legacy support.
        const unescapedTranslation = htmlUnescape(translation);

        // This next html-related operation will remove html tags so that IntlMessageFormat()
        // does not choke on them. It adds them back afterwards.
        const [removeHtml, addBackHtml] = getTagReplaceMatchers();
        const htmlSafeTranslation = unescapedTranslation.replace(HTML_TAG_REGEX, removeHtml);
        const formattedMessage = new IntlMessageFormat.IntlMessageFormat(htmlSafeTranslation, locale).format(options);
        message = formattedMessage.replace(HTML_TAG_REPLACE_REGEX, addBackHtml);
      }
      // No message found, show the key
      else if (messageKey) {
        message = messageKey;
      } else {
        message = "";
      }

      return message;
    },
    // Localizes dates
    date: function (year, month, day, options) {
      options = options || {};
      /*EGE232291 - change date constructor from no-arg to parameterized version
      The default no-arg constructor returns a date instance initialized to current date and
      subsequent set operations are performed on this date instance resulting in incorrect values.
      eg. getting a date instance on Jan 29,2019  and then setting month to Feb actually returns a March date
      as Feb 29 is not a valid date
      */
      const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));

      const parsedTime = moment.utc(date);

      if (options.locale) {
        parsedTime.locale(transformLocaleName(options.locale));
      }

      if (options.pattern) {
        console.warn("'Pattern' is deprecated because it does not localize correctly, please use an appropriate 'style' type");
        return parsedTime.format(options.pattern);
      } else {
        switch (options.style) {
          case 'iso':
            return parsedTime.format('YYYY-MM-DD');
          case 'short':
            return parsedTime.format('l');
          case 'medium':
            return parsedTime.format('ll');
          case 'long':
            return parsedTime.format('LL');
          case 'full':
            return parsedTime.format('LLLL');
          default:
            return parsedTime.format('ll');
        }
      }
    },
    // Localizes times
    time: function (hours, minutes, options) {
      options = options || {};
      const date = new Date();
      date.setUTCHours(hours);
      date.setUTCMinutes(minutes);
      date.setUTCSeconds(0); //just incase
      date.setUTCMilliseconds(0); //just incase

      const parsedTime = moment.utc(date);

      if (options.locale) {
        parsedTime.locale(transformLocaleName(options.locale));
      }

      if (options.pattern) {
        return parsedTime.format(options.pattern);
      }

      return parsedTime.format('LT');
    },
    // Localizes durations
    duration: function (value, options) {
      var days, hours, mins, secs;
      var options = options || {};
      var duration = "";
      var hoursSuffixLong = I18n.t("uitk_layover_hours_suffix_long", options).replace("&amp;nbsp;", "&nbsp;");
      var minsSuffixLong = I18n.t("uitk_layover_mins_suffix_long", options).replace("&amp;nbsp;", "&nbsp;");
      var secsSuffixLong = I18n.t("uitk_layover_secs_suffix_long", options).replace("&amp;nbsp;", "&nbsp;");
      var hoursSuffix, minsSuffix, secsSuffix;

      if (options.locale) {
        options.locale = transformLocaleName(options.locale);
      }

      hoursSuffix = getSpacedSuffix(options.locale, I18n.t("uitk_layover_hours_suffix", options).replace("&amp;nbsp;", "&nbsp;"));
      minsSuffix = getSpacedSuffix(options.locale, I18n.t("uitk_layover_mins_suffix", options).replace("&amp;nbsp;", "&nbsp;"));
      secsSuffix = getSpacedSuffix(options.locale, I18n.t("uitk_layover_secs_suffix", options).replace("&amp;nbsp;", "&nbsp;"));

      // Calculate duration values
      if (options.seconds) {
        days = Math.floor(value / (3600 * 24));
        hours = Math.floor(value / 3600);
        mins = Math.floor((value - (hours * 3600)) / 60);
        secs = value - (hours * 3600) - (mins * 60);
      }
      // value is minutes
      else {
        days = Math.floor(value / (60 * 24));
        hours = Math.floor(value / 60);
        mins = value % 60;
      }

      // Determine how to label the values
      // 1h 1m 1s or 1h 1s or 1m or 1s or 1m 1s
      if (options.hideZeros) {
        if (hours > 0 && mins > 0) {
          duration = hours + hoursSuffix + " " + mins + minsSuffix;
          if (options.seconds && secs > 0) {
            duration += " " + secs + secsSuffix;
          }
        } else if (hours > 0 && mins == 0) {
          if (options.seconds && secs > 0) {
            duration = hours + hoursSuffix + " " + secs + secsSuffix;
          } else {
            duration = hours + " " + hoursSuffixLong;
          }
        } else if (hours == 0 && mins > 0) {
          if (options.seconds && secs > 0) {
            duration = mins + minsSuffix + " " + secs + secsSuffix;
          } else {
            duration = mins + " " + minsSuffixLong;
          }
        } else if (hours == 0 && mins == 0) {
          if (options.seconds && secs > 0) {
            duration = secs + " " + secsSuffixLong;
          } else {
            duration = mins + " " + minsSuffixLong;
          }
        }
      } else if (options.full) {
        if (hours > 0 && mins > 0) {
          duration = hours + " " + hoursSuffixLong + " " + mins + " " + minsSuffixLong;
        } else if (hours > 0 && mins == 0) {
          duration = hours + " " + hoursSuffixLong;
        } else if (hours == 0 && mins > 0) {
          duration = mins + " " + minsSuffixLong;
        } else if (hours == 0 && mins == 0) {
          duration = mins + " " + minsSuffixLong;
        }

        if (options.seconds && secs > 0) {
          if (hours == 0 && mins == 0) {
            duration = secs + " " + secsSuffixLong;
          } else {
            duration += " " + secs + " " + secsSuffixLong;
          }
        }
      } else {
        if (options.seconds) {
          duration = hours + hoursSuffix + " " + mins + minsSuffix + " " + secs + secsSuffix;
        } else {
          duration = hours + hoursSuffix + " " + mins + minsSuffix;
        }
      }

      return duration;
    },
    // Localizes numbers
    number: function (number, options) {
      var options = options || {},
        tmpLocale,
        val;

      if ($.isNumeric(number)) {
        if (options.locale) {
          tmpLocale = I18n.currentLocale();
          I18n.locale = transformLocaleName(options.locale);
        }

        if (!options.zeros) {
          options.strip_insignificant_zeros = true;
        }

        val = I18n.toNumber(number, options);


        if (tmpLocale) {
          I18n.locale = tmpLocale;
        }

        return val;
      } else {
        return "";
      }
    },
    // Localizes currencies
    currency: function (amount, options) {
      var options = options || {},
        currencyCode = options.code,
        round = options.round || false,
        localeOverride = options.locale ? transformLocaleName(options.locale) : undefined,
        localOverridedCurrencyCode,
        prevLocale,
        currencyOptions = {},
        val,
        defaultCode = I18n.t('number.currency.format.code');

      if ($.isNumeric(amount)) {
        // If no code & no locale, we're going default (do nothing)
        // No locale but code
        if (currencyCode && !localeOverride) {
          // If the code matches, we're going default (do nothing)
          // No match, then it's foreign currency
          if (currencyCode != defaultCode) {
            currencyOptions = {
              unit: currencyCode
            };
          }
        }
        // If code & locale
        else if (currencyCode && localeOverride) {
          toggleCurrentLocale(true);
          localOverridedCurrencyCode = I18n.t("number.currency.format.code", I18n.local);
          // If the code matches, we're going default (do nothing)
          // No match, then it's foreign currency
          if (currencyCode != localOverridedCurrencyCode) {
            currencyOptions = {
              unit: currencyCode
            };
          }
        }
        // If no code but locale, overrides defaults
        else if (!currencyCode && localeOverride) {
          toggleCurrentLocale(true);
        }

        // If round and JPY (Japanese Yen does not have fractions)
        // The config in date-and-currency.js is not used in cases where the locale and currency code are not the same
        // e.g. locale en-US with code JPY which en-US number format is used and has correct precision of 2
        // but we need to override that back to precision 0 for JPY.
        if (round || currencyCode == "JPY") {
          currencyOptions.precision = 0;
        }

        val = I18n.toCurrency(amount, currencyOptions);

        if (prevLocale) {
          toggleCurrentLocale(false);
        }

        return val;
      } else {
        return "";
      }


      function toggleCurrentLocale(override) {
        if (override) {
          prevLocale = I18n.currentLocale();
          I18n.locale = localeOverride;
        } else {
          I18n.locale = prevLocale;
        }
      }
    }
  }
  return uitk;
})(window.uitk ?? {});