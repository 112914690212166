(() => {
    document.body.addEventListener('click', event => {
        const delegateTarget = event.target.closest('li.menu-item-has-children');
        if(!delegateTarget) {
            document.body.querySelectorAll('.header-menu-active').forEach(e => 
                e.classList.remove("header-menu-active"));
            return;
        }
        const submenu = delegateTarget.querySelector("li.menu-item-has-children > .submenu");

        document.body.querySelectorAll('.header-menu-active').forEach(e => 
            e.classList.remove("header-menu-active"));
        
        submenu.classList.add("header-menu-active");
        uitk.tooltips.hideAll();
        event.stopPropagation();
    });
})();